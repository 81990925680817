<template>
  <div>
    <el-row :gutter="10"
            style="padding-bottom:10px">
      <el-col :span="4">
        <el-select style="width:100%"
                   v-model="student_id"
                   filterable
                   clearable
                   @change="changeStudent"
                   placeholder="选择负责人">
          <el-option v-for="item in studentList"
                     :key="item.id"
                     :label="item.truename + ' ' + item.verifiedMobile"
                     :value="item.id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select style="width:100%"
                   v-model="subject_id"
                   @change="initData"
                   placeholder="选择科目">
          <el-option v-for="item in subjectList"
                     :key="item.subject_id"
                     :label="item.subject_name"
                     :value="item.subject_id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <el-date-picker v-model="dates"
                        style="width:100%"
                        type="datetimerange"
                        :default-time="['00:00:00', '23:59:59']"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        range-separator="至"
                        start-placeholder="开始日期"
                        @change="changeDate"
                        end-placeholder="结束日期">
        </el-date-picker>
      </el-col>
      <el-col :span="10">
        <el-button type="primary"
                   @click="initData">查询</el-button>
        <el-button type="primary"
                   @click="exportsData">导出</el-button>

      </el-col>
    </el-row>
    <el-row>

    </el-row>
    <el-table border
              stripe
              highlight-current-row
              v-if="info.user_weak"
              :max-height="$store.state.tableHeight + 100"
              :data="info.user_weak"
              style="width: 100%">
      <el-table-column prop="check_point_id"
                       align="center"
                       width="150"
                       label="考点ID" />
      <el-table-column prop="check_point_desc"
                       align="center"
                       label="考点名称" />
      <el-table-column prop="question_times"
                       align="center"
                       label="学生做题次数" />
      <el-table-column prop="score_rate"
                       align="center"
                       label="学生得分率" />
      <el-table-column prop=""
                       width="150"
                       align="center"
                       label="操作">

        <template slot-scope="{row}">
          <span v-if="row.has_check_point2">
            <el-button type="text"
                       @click="toSub(row)"> 查看二级考点</el-button>
          </span>
        </template>

      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data () {
    return {
      info: {},
      studentList: [],
      subjectList: [],
      student_id: '',
      subject_id: '',
      end_time: '',
      start_time: '',
      dates: [],
    }
  },
  created () {
    this.getStudentList()
    this.getSubjectList()
  },
  methods: {
    changeDate () {
      this.start_time = this.dates[0]
      this.end_time = this.dates[1]
    },
    initData () {
      if (this.student_id == '' || this.subject_id == '') {
        return this.$message.warning('学生，科目必选')
      }

      let form = {
        student_id: this.student_id,
        subject_id: this.subject_id,
        start_time: this.start_time,
        end_time: this.end_time
      }
      this.$http({
        url: '/api/v1/weak/student_detail',
        method: 'post',
        data: form
      }).then(res => {
        this.info = res.data
      })
    },
    changeStudent (v) {
      localStorage.setItem('student_condition_info_default_student_id', v)
      this.getSubjectList()
    },
    toSub (row) {
      let params = {
        student_id: this.student_id,
        subject_id: this.subject_id,
        check_point_id: row.check_point_id,
        start_time: this.start_time,
        end_time: this.end_time
      }
      this.$router.push({
        path: '/studentCondition/info/subpoint',
        query: params
      })
    },
    getSubjectList () {
      this.$http({
        // url: '/api/v1/weak/subjects',
        url: '/api/v1/weak/common_subjects',
        methods: 'get',
        params: {
          user_id: this.student_id
        }
        // method: 'post'
      }).then(res => {
        this.subjectList = res.data.list
        if (this.subjectList.length) {
          this.subject_id = this.subjectList[0].subject_id
          this.initData()
        } else {
          this.subject_id = ''
          this.info = {
            user_weak: []
          }
        }
      })
    },
    getStudentList () {
      this.$http({
        url: '/api/v1/weak/students',
        method: 'post'
      }).then(res => {
        this.studentList = res.data.list
        if (this.studentList.length > 0) {
          // this.student_id = this.studentList[0].id
          var localSid = localStorage.getItem('student_condition_info_default_student_id')
          this.student_id = localSid == null ? this.studentList[0].id : parseInt(localSid)
        }
        this.getSubjectList()
        // this.initData()
      })
    },
    exportsData () {
      if (this.student_id == '' || this.subject_id == '') {
        return this.$message.warning('学生，科目必选')
      }
      this.$http({
        url: '/api/v1/weak/export_detail',
        method: 'get',
        responseType: "blob",
        params: {
          student_id: this.student_id,
          subject_id: this.subject_id,
          start_time: this.start_time,
          end_time: this.end_time

        }
      }).then(res => {
        let student = this.studentList.filter(item => item.id == this.student_id)[0].truename
        let subject = this.subjectList.filter(item => item.subject_id == this.subject_id)[0].subject_name
        const a = document.createElement("a")
        // let url = null
        // const binaryData = [];
        // binaryData.push(res.data);
        // url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/pdf;chartset=UTF-8' }));
        // a.href = url
        a.href = window.URL.createObjectURL(res)

        // 姓名科目个人详细统计
        a.download = `${student}${subject}个人详细统计.xlsx`
        a.click()
      })
    },


  }
}
</script>

<style lang="scss" scoped>
</style>
